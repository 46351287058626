import { FC, useEffect, useState } from "react";

import { useWorkerLayoutForceAtlas2 } from "@react-sigma/layout-forceatlas2";
import Graph from "graphology";
import { useRegisterEvents, useSetSettings, useSigma } from "@react-sigma/core";
import { useLoadGraph } from "@react-sigma/core";

import fetchGraph from "../lib/fetchGraph";
import {AutosuggestOption, NodeType, EdgeType} from '../lib/util';

type Props = {
  hoveredNode: string | null,
  setHoveredNode: (val: string | null) => void,
  draggedNode: string | null,
  setDraggedNode: (val: string | null) => void,
  selections: Array<string>,
  setSelections: (val: Array<string>) => void,
  selectedAutocompleteOptions: Array<AutosuggestOption>,
  setSelectedAutocompleteOptions: (val: Array<AutosuggestOption>) => void,
  graphData: Graph|null,
  setGraphData: (val: Graph|null) => void,
  layoutSettings: Record<string, any>
}
export const GraphLoader: FC<Props> = ({
  hoveredNode, setHoveredNode,
  draggedNode, setDraggedNode,
  selections, setSelections,
  selectedAutocompleteOptions, setSelectedAutocompleteOptions,
  graphData, setGraphData,
  layoutSettings
}) => {
  const sigma = useSigma<NodeType, EdgeType>();
  const registerEvents = useRegisterEvents<NodeType, EdgeType>();
  const setSettings = useSetSettings<NodeType, EdgeType>();
  const loadGraph = useLoadGraph();
  const [shiftDown, setShiftDown] = useState(false);

  console.log('shiftDown', shiftDown);

  useEffect(() => {
    const handleShift = (up: boolean) => (e: any) => {
      if (e.key === 'Shift') {
        setShiftDown(up);
      }
    };

    window.addEventListener('keyup', handleShift(false));
    window.addEventListener('keydown', handleShift(true));
  }, [])

  const { start, stop } = useWorkerLayoutForceAtlas2({settings: layoutSettings});
  useEffect(() => {
    if (graphData) {
      console.log('Running formatter');
      start();
      setTimeout(() => {
        console.log('stopping formatter');
        stop();
        // Persist the graph with layout applied
        setGraphData(sigma.getGraph().copy());
      }, 750);
    }
  }, [graphData === null, start, stop, sigma])

  // Fetch Graph
  useEffect(() => {
    fetchGraph().then((data) => {
      const graph = new Graph();
      graph.import(data);
      setGraphData(graph);
      loadGraph(graph);
    });
  }, [loadGraph, setGraphData]);

  // Handle Graph Interactions
  useEffect(() => {
    registerEvents({
      enterNode: (event) => setHoveredNode(event.node),
      leaveNode: () => setHoveredNode(null),
      doubleClickNode: (e) => {
        if (selections.includes(e.node)) {
          setSelections(selections.filter((a: string) => a !== e.node));
          setSelectedAutocompleteOptions(selectedAutocompleteOptions.filter((a: AutosuggestOption) => a.id !== e.node));
          // removing nodes will move the graph so leaveNode might not fire
          setHoveredNode(null);
        } else {
          const newOption = { id: e.node, label: sigma.getGraph().getNodeAttribute(e.node, "label"), type: 'name', subType: sigma.getGraph().getNodeAttribute(e.node, "nodeType") };
          setSelectedAutocompleteOptions([...selectedAutocompleteOptions, newOption]);
          setSelections(selections.concat(e.node));
        }
        e.preventSigmaDefault();
      },
      downNode: (e) => {
        setDraggedNode(e.node);
        sigma.getGraph().setNodeAttribute(e.node, "highlighted", true);
      },
      // On mouse move, if the drag mode is enabled, we change the position of the draggedNode
      mousemovebody: (e) => {
        if (!draggedNode) return;
        const graph = sigma.getGraph();

        // Get "dropped" position of node
        const pos = sigma.viewportToGraph(e);

        // Get position delta
        const {x, y} = graph.getNodeAttributes(draggedNode);
        const deltaX = pos.x - x;
        const deltaY = pos.y - y;

        // Set new position of dragged node
        graph.setNodeAttribute(draggedNode, "x", pos.x);
        graph.setNodeAttribute(draggedNode, "y", pos.y);

        // Set new position of dragged neighbors
        if (shiftDown){
          const neighbors = graph.neighbors(draggedNode);
          for (const neighbor of neighbors) {
            const neighborCount = graph.neighbors(neighbor).length;
            if (neighborCount < neighbors.length) {
              graph.setNodeAttribute(neighbor, "x", graph.getNodeAttribute(neighbor, "x") + deltaX);
              graph.setNodeAttribute(neighbor, "y", graph.getNodeAttribute(neighbor, "y") + deltaY);
            }
          }
        }


        // Prevent sigma to move camera:
        e.preventSigmaDefault();
        e.original.preventDefault();
        e.original.stopPropagation();
      },
      // On mouse up, we reset the autoscale and the dragging mode
      mouseup: () => {
        if (draggedNode) {
          setDraggedNode(null);
          if (!selectedAutocompleteOptions.some((a: AutosuggestOption) => a.id === draggedNode)) {
            sigma.getGraph().setNodeAttribute(draggedNode, "highlighted", false);
          }
        }
      },
      // Disable the autoscale at the first down interaction
      mousedown: () => {
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
    });
  }, [
    registerEvents, sigma, draggedNode, setDraggedNode, setHoveredNode,
    selections, setSelections, selectedAutocompleteOptions, setSelectedAutocompleteOptions
  ]);

  // Handle Node Renderring
  useEffect(() => {
    setSettings({
      nodeReducer: (node, data) => {
        data = data || {}
        const graph = sigma.getGraph();
        const newData = { ...data, highlighted: data.highlighted || false };
        const colors = [
          "#FFCDD2",
          "#F8BBD0",
          "#CE93D8",
          "#90CAF9",
          "#80DEEA",
          "#A5D6A7",
          "#76FF03",
          "#FDD835",
          "#FFCC80",
          "#7C4DFF",
          "#EF5350",
          "#EC407A",
          "#AB47BC",
          "#2196F3",
          "#26C6DA",
          "#66BB6A",
          "#D4E157",
          "#FF1744",
          "#FFA726",
          "#90A4AE"
        ]

        newData.size = 3;
        switch (newData.nodeType) {
          case 'Aspect':
            newData.size = 5;
            newData.color = colors[0];
            break;
          case 'Fragment':
            newData.size = 5;
            newData.color = colors[1];
            break;
          case 'Super':
            newData.size = 5;
            newData.color = colors[2];
            break;
          case 'Melee':
            newData.size = 5;
            newData.color = colors[3];
            break;
          case 'Grenade':
            newData.size = 5;
            newData.color = colors[4];
            break;
          case 'Class Ability':
            newData.size = 5;
            newData.color = colors[5];
            break;
          case 'Exotic Weapon':
            newData.size = 3;
            newData.color = colors[6];
            break;
          case 'Exotic Armor':
            newData.size = 3;
            newData.color = colors[7];
            break;
          case 'Exotic Class Item Perk':
            newData.size = 3;
            newData.color = colors[8];
            break;
          case 'Armor Mod General':
            newData.size = 2;
            newData.color = colors[9];
            break;
          case 'Weapon Trait':
            newData.size = 2;
            newData.color = colors[10];
            break;
          case 'Weapon Trait Enhanced':
            newData.size = 2;
            newData.color = colors[11];
            break;
          case 'Weapon Trait Origin':
            newData.size = 2;
            newData.color = colors[12];
            break;
          case 'Weapon Perk':
            newData.size = 2;
            newData.color = colors[13];
            break;
          case 'Artifact Perk':
            newData.size = 3;
            newData.color = colors[14];
            break;
          case 'Activity Armor Mod':
            newData.size = 2;
            newData.color = colors[15];
            break;
          case 'Weapon Mod':
            newData.size = 2;
            newData.color = colors[16];
            break;
          case 'Buff':
          case 'Debuff':
            newData.size = 4;
            newData.color = colors[17];
            break;
          case 'Elemental Pickup':
            newData.size = 4;
            newData.color = colors[18];
            break;
          case 'Champion':
            newData.size = 4;
            newData.color = colors[19];
            break;
          case 'Other':
          case 'Ammo':
          case 'Class':
            newData.size = 2;
            newData.color = colors[19];
            break;
          default:
            throw new Error('no color specified for : ' + newData.nodeType);
        }

        if (hoveredNode) {
          const isNodeFocused = hoveredNode === node;
          const isChildFocused = graph.hasNode(hoveredNode) && graph.neighbors(hoveredNode).includes(node);

          if (isNodeFocused || isChildFocused) {
            newData.highlighted = true;
            newData.hidden = false;
          } else {
            newData.hidden = selections.length === 0;
            newData.highlighted = false;
          }
        }

        return newData;
      },
      edgeReducer: (edge, data) => {
        const graph = sigma.getGraph();
        const newData = { ...data, hidden: false };

        if (hoveredNode && graph.extremities(edge).includes(hoveredNode)) {
          newData.size = 3;
        }
        return newData;
      },
    });
    // selectedOptions is a necessary dependency or else the graph won't show the rest of the nodes when removing the last filter
  }, [hoveredNode, setSettings, sigma, selections, selectedAutocompleteOptions.map(a => a.label).join(',')]);

  return null
};
