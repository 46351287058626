import React, { ChangeEvent, useEffect } from "react";
import Graph from "graphology";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Button, Typography } from '@mui/material';

import { useSigma } from "@react-sigma/core";
import AbstractGraph from "graphology-types";

import { getAutocompleteOptions, getResultsForAutocompleteOption } from "../lib/searchGraph";
import {AutosuggestOption} from '../lib/util';

import Todo from './Admin/Todo';
import Admin from './Admin';

const debug = false;

const isOptionEqual = (a:AutosuggestOption, val:AutosuggestOption) =>
  (a.id === null || a.id === val.id)
    && a.label === val.label
    && a.type === val.type
    && a.subType === val.subType;

type Props = {
  runLayout: Function,
  graphData: Graph|null,
  search: string,
  setSearch: (val: string) => void,
  selections: Array<string>,
  setSelections: (val: Array<string>) => void,
  selectedAutocompleteOptions: Array<AutosuggestOption>,
  setSelectedAutocompleteOptions: (val: Array<AutosuggestOption>) => void,
  autocompleteOptions: Array<AutosuggestOption>,
  setAutocompleteOptions: (val: Array<AutosuggestOption>) => void,
  hoveredNode: string | null,
  setHoveredNode: (val: string | null) => void,
}

export default function SearchAppBar({
  runLayout, graphData,
  search, setSearch,
  selections, setSelections,
  selectedAutocompleteOptions, setSelectedAutocompleteOptions,
  autocompleteOptions, setAutocompleteOptions,
  hoveredNode, setHoveredNode
}: Props) {

  const sigma = useSigma();

  //@ts-ignore
  window.sigma = sigma;

  const manualZoom = () => {
    // get graphcoords of bounding box
    const {x:[xMin,xMax],y:[yMin,yMax]} = sigma.getBBox();
    // Convert graph to pixel coords
    const maxCoord = sigma.graphToViewport({ x: xMax, y: yMax });
    const minCoord = sigma.graphToViewport({ x: xMin, y: yMin });

    // Determine the pixeldimensions used by current graph render
    const yBoxSpread = Math.max(maxCoord.y, minCoord.y) - Math.min(maxCoord.y, minCoord.y);
    const xBoxSpread = Math.max(maxCoord.x, minCoord.x) - Math.min(maxCoord.x, minCoord.x);

    // get graph's total area in pixelcoords
    const { width, height } = sigma.getDimensions();

    const yZoomRatio = yBoxSpread / height;
    const xZoomRatio = xBoxSpread / width;

    const roundToHundredth = (n: number) => Math.round(n * 100) / 100;

    const percentOfSpaceUsed = Math.max(yZoomRatio, xZoomRatio);
    const idealSpaceUsed = 0.80;
    const ratioMultiplier = roundToHundredth(percentOfSpaceUsed) / idealSpaceUsed;
    const ratio = roundToHundredth(ratioMultiplier * sigma.getCamera().ratio);

    // 1 is "zoomed all the way out" so we cap it there
    // Selecting one node leads to a ratio of 0 since it technically has no area, which doesn't work well
    const adjustedRatio = Math.max(Math.min(ratio, 1), 0.03);
    const payload = { x: 0.5, y: 0.5, angle: 0, ratio: adjustedRatio };

    if (debug) {
      console.log({maxCoord, minCoord, bbox: sigma.getBBox(), width, height, xBoxSpread, yBoxSpread})
      console.log({ xZoomRatio, yZoomRatio, percentOfSpaceUsed, idealSpaceUsed, ratio, adjustedRatio});
      console.log('sigma.getCamera().getState()', sigma.getCamera().getState());
      console.log('sigma.getCamera().setState()', payload);
    }

    sigma.getCamera().setState(payload)
  }



  /**
   * When the search input changes, recompute the autocomplete values.
   */
  useEffect(() => {
    if (!graphData) return;
    const newValues = getAutocompleteOptions(graphData, search);
    const deduped = newValues
      // Remove values that are already selected
      .filter((val:AutosuggestOption) => selectedAutocompleteOptions.every((a:AutosuggestOption) => !isOptionEqual(a, val)))
      // Sort by shortest to longest
      .sort((a:AutosuggestOption, b:AutosuggestOption) => a.label.length - b.label.length);

    if (debug) {
      console.log('new autocompleteOptions', newValues, deduped);
      console.log('selectedAutocompleteOptions', selectedAutocompleteOptions);
    }

    setAutocompleteOptions(deduped);
    setHoveredNode(null);
  }, [search, setAutocompleteOptions, selectedAutocompleteOptions]);

  /**
   * On change event handler for the search input, to set the state.
   */
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e) return;
    const searchString = e.target.value;
    setSearch(searchString);
  };

  //@ts-ignore
  window.updateDisplayGraph = () => {
    if (!graphData) return;

    const graph = graphData.copy() as AbstractGraph;

    if (selections.length > 0) {
      console.time('[updateDisplayGraph] make new graph');
      selections.forEach((node:string) => {
        if (graph.hasNode(node)) {
          graph.setNodeAttribute(node, 'selected', true);
          if (selectedAutocompleteOptions.some((a: AutosuggestOption) => a.id === node)) {
            graph.setNodeAttribute(node, 'highlighted', true);
          }
        }
      });

      const pureDanglers = graph.nodes().filter(a => {
        const attributes = graph.getNodeAttributes(a)
        return attributes.label === "Orb of Power"
        || ["Artifact Perk", "Champion"].includes(attributes.nodeType)
      })
        .concat(selections);


      // prevent infinite loop from cyclical relationships
      const visited: Record<string, boolean> = {};
      const getRelevantRelations = (a:string, relations: Array<string> = []): Array<string> => {
        if (visited[a]) return relations;
        else visited[a] = true;

        relations.push(a);

        graph.inNeighbors(a).forEach(a => {
          if (pureDanglers.includes(a)) relations.push(a);
        })

        return graph.outboundNeighbors(a).reduce((acc, cur) => {
          return [...acc, ...getRelevantRelations(cur)];
        }, relations);
      };

      graph.filterNodes((node, attributes) => attributes.selected)
        .reduce((acc, node) => {
          // If a node is selected, force show any neighbors of any directionality
          if (selectedAutocompleteOptions.some(a => a.id && a.id === node)) {
            acc.push(...graph.neighbors(node));
          }

          // Add outbound neighbors
          acc.push(...getRelevantRelations(node))
          return acc;
        }, [] as Array<string>)
      .forEach(node => graph.setNodeAttribute(node, 'selected', true));

      // get graph's total area in pixelcoords
      const { width, height } = sigma.getDimensions();
      // If this state is an extension of subset of a previous view
      const previousStateWasFiltered = sigma.getGraph().nodes().length < graphData.nodes().length;

      graph.forEachNode((key, attributes) => {
        if (!attributes.selected) {
          graph.dropNode(key);
        } else {
          if (previousStateWasFiltered) {
            // Get node coords in pixel
            const {x,y} = sigma.graphToViewport({ x: attributes.x, y: attributes.y });

            // Reduce coords into a 0-1 number range like math.random.
            // This is done to reduce the likelihood of a completely random looking graph after adding a second selection
            graph.setNodeAttribute(key, 'x', x/width)
            graph.setNodeAttribute(key, 'y', y/height)
          } else {
            graph.setNodeAttribute(key, 'x', Math.random())
            graph.setNodeAttribute(key, 'y', Math.random())
          }
        }
      });
      console.timeEnd('[updateDisplayGraph] make new graph');

      console.time('[updateDisplayGraph] applying layout');
      runLayout(graph);
      console.timeEnd('[updateDisplayGraph] applying layout');
    }

    console.time('[updateDisplayGraph] setting graph');
    // if we don't run clear we get an unhandled vector computation error within the library
    sigma.getGraph().clear();
    sigma.setGraph(graph);
    console.timeEnd('[updateDisplayGraph] setting graph');
    console.log(`[updateDisplayGraph] orig:${graphData.nodes().length} new:${graph.nodes().length}`);

    window.requestAnimationFrame(() => manualZoom());
  }

  useEffect(() => {
    // @ts-ignore
    if(window.updateDisplayGraph) window.updateDisplayGraph();
  }, [selections]);

  // @ts-ignore
  const onSelectionAddition = (e, value: Array<AutosuggestOption>, reason) => {
    if (!graphData) return;

    value.forEach(val => {
      if (val.type === 'meta') {
        setSearch(val.label);
      } else {
        if (selectedAutocompleteOptions.every((a: AutosuggestOption) => !isOptionEqual(a, val))) {
          setSelectedAutocompleteOptions([...selectedAutocompleteOptions, val]);

          const newSelections = getResultsForAutocompleteOption(graphData, val);
          setSelections([...selections, ...newSelections].filter((a,i,c) => c.indexOf(a) === i));

          setSearch('');
        }
      }
    })
  }

  const onSelectionRemoval = (node: AutosuggestOption) => {
    if (!graphData) return;

    // Remove from selected options
    const newSelectedOptions = selectedAutocompleteOptions.filter((a: AutosuggestOption) => !isOptionEqual(a, node));
    setSelectedAutocompleteOptions(newSelectedOptions);

    // Regenerate selectedNodes
    // NOTE: Layout updates based on remaining selections will be performed in response to selection updates
    const newSelections: Array<string> = [];
    for (const option of newSelectedOptions) {
      newSelections.push(...getResultsForAutocompleteOption(graphData, option));
    }
    setSelections(newSelections);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="absolute">
        <Toolbar variant='dense'>
          <Box sx={{ flexGrow: 2 }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              Destiny Graph
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 4 }} />
          <Box sx={{ flexGrow: 1,  display: 'flex', justifyContent: 'right', marginRight: '10px' }}>
            <SearchIcon />
          </Box>
          <Box sx={{ flexGrow: 17 }}>
            <Autocomplete
              multiple
              sx={{ backgroundColor: 'white' }}
              inputValue={search}
              autoHighlight
              onInputChange={(e) => onInputChange(e as ChangeEvent<HTMLInputElement>)}
              //@ts-ignore
              getOptionLabel={(option: AutosuggestOption) => {
                if (option.type === 'meta') {
                  return `${option.label}`;
                } else {
                  // TODO: ignition won't show up because
                  const prefix = search.trim().startsWith(option.type)
                    ? option.type
                    : option.subType;

                  return `${prefix}:${option.label}`;
                }
              }}
              //@ts-ignore
              getOptionKey={(a:AutosuggestOption) => `${a.id}-${a.label}-${a.subType}-${a.type}`}
              options={autocompleteOptions}
              value={selectedAutocompleteOptions}
              isOptionEqualToValue={isOptionEqual}
              onChange={onSelectionAddition}
              renderTags={(value: AutosuggestOption[], getTagProps: Function) => null}
              renderInput={(params) => {
                return (
                <TextField
                  {...params}
                  sx={{ bg: 'white' }}
                  variant="standard"
                  placeholder=" Search..."
                />
              )}}
            />
          </Box>

          <Box sx={{ flexGrow: 1  }} />

          <Box sx={{ flexGrow: 1 }}>
            <Button
              variant={'contained'}
              size={'small'}
              onClick={() => {
                sigma.getGraph().nodes().forEach(a => {
                  sigma.getGraph().setNodeAttribute(a, 'x', Math.random());
                  sigma.getGraph().setNodeAttribute(a, 'y', Math.random());
                });
                runLayout(sigma.getGraph());
              }}
              disabled={selectedAutocompleteOptions.length === 0}>
              Regenerate
            </Button>
          </Box>

          <Box sx={{ flexGrow: 1  }}>
            <Button
              variant={'contained'}
              size={'small'}

              onClick={() => {
                if (selectedAutocompleteOptions.length === 0 && graphData !== null) {
                  sigma.setGraph(graphData);
                } else {
                  runLayout(sigma.getGraph());
                  manualZoom();
                }
              }}>
              Run Layout
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1  }}>
            {localStorage.getItem('isAdmin') === 'true'
              ? <Todo />
              : null}
          </Box>
          <Box sx={{ flexGrow: 1  }}>
            {localStorage.getItem('isAdmin') === 'true'
              ? <Admin />
              : null}
          </Box>
        </Toolbar>
        <Toolbar variant='dense' sx={{backgroundColor: 'white', maxHeight: '40px', overflow: 'scroll' }}>
            {selectedAutocompleteOptions.length
              ? selectedAutocompleteOptions.map((option: AutosuggestOption, index: number) => (
                  <Box sx={{ display: 'flex', flexGrow: 1, alignContent: 'center', justifyContent: 'center' }}>
                  <Chip
                    variant="filled"
                    onMouseEnter={() => {
                      if (!hoveredNode) setHoveredNode(option.id);
                    }}
                    onMouseLeave={() => {
                      if (hoveredNode) setHoveredNode(null);
                    }}
                    sx={{ margin:'auto'}}
                    label={`${option.subType ?? option.type}:${option.label}`}
                    key={option.label}
                    onDelete={() => onSelectionRemoval(option)} />
                  </Box>
                ))
            : <Box sx={{ display: 'flex', flexGrow: 1, alignContent: 'center', justifyContent: 'center' }}>
                <Typography sx={{color: 'rgba(0,0,0,0.7)', textAlign: 'center'}}>
                  Add some items to explore their synergies! For example, "name:Ignition"
                </Typography>
              </Box>
            }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
