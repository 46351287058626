import React from "react";
import { Chip, Paper } from "@mui/material";
import {AutosuggestOption} from '../lib/util';
import Graph from "graphology";

// Sample values for selectedAutocompleteOptions
// [{"id":"Knock_Em_Down_Aspect_Hunter_Solar_","label":"Knock 'Em Down","type":"name"},{"id":"Gunpowder_Gamble_Aspect_Hunter_Solar_","label":"Gunpowder Gamble","type":"name"},{"id":"Healing_Grenade_Grenade_Warlock_Prismatic_","label":"Healing Grenade","type":"name"},{"id":"Knife_Trick_Melee_Hunter_Prismatic_","label":"Knife Trick","type":"name"},{"id":"Marksman_s_Dodge_Class_Ability_Hunter_","label":"Marksman's Dodge","type":"name"},{"id":"Golden_Gun_Marksman_Super_Hunter_Prismatic_","label":"Golden Gun - Marksman","type":"name"},{"id":"Ember_of_Empyrean_Fragment_Hunter_Warlock_Titan_Solar_","label":"Ember of Empyrean","type":"name"},{"id":"Ember_of_Torches_Fragment_Hunter_Warlock_Titan_Solar_","label":"Ember of Torches","type":"name"},{"id":"Ember_of_Char_Fragment_Hunter_Warlock_Titan_Solar_","label":"Ember of Char","type":"name"},{"id":"Ember_of_Mercy_Fragment_Hunter_Warlock_Titan_Solar_","label":"Ember of Mercy","type":"name"},{"id":"Sunshot_Exotic_Weapon_Solar","label":"Sunshot","type":"name"},{"id":"Assassin_s_Cowl_Exotic_Armor_Hunter_","label":"Assassin's Cowl","type":"name"},{"id":"Heal_Clip_Weapon_Trait_Solar","label":"Heal Clip","type":"name"}]

// [{"id":"Knock_Em_Down_Aspect_Hunter_Solar_","label":"Knock 'Em Down","type":"name"},{"id":"Gunpowder_Gamble_Aspect_Hunter_Solar_","label":"Gunpowder Gamble","type":"name"},{"id":"Healing_Grenade_Grenade_Warlock_Prismatic_","label":"Healing Grenade","type":"name"},{"id":"Knife_Trick_Melee_Hunter_Prismatic_","label":"Knife Trick","type":"name"},{"id":"Marksman_s_Dodge_Class_Ability_Hunter_","label":"Marksman's Dodge","type":"name"},{"id":"Golden_Gun_Marksman_Super_Hunter_Prismatic_","label":"Golden Gun - Marksman","type":"name"},{"id":"Ember_of_Empyrean_Fragment_Hunter_Warlock_Titan_Solar_","label":"Ember of Empyrean","type":"name"},{"id":"Ember_of_Torches_Fragment_Hunter_Warlock_Titan_Solar_","label":"Ember of Torches","type":"name"},{"id":"Ember_of_Char_Fragment_Hunter_Warlock_Titan_Solar_","label":"Ember of Char","type":"name"},{"id":"Ember_of_Mercy_Fragment_Hunter_Warlock_Titan_Solar_","label":"Ember of Mercy","type":"name"},{"id":"Sunshot_Exotic_Weapon_Solar","label":"Sunshot","type":"name"},{"id":"Assassin_s_Cowl_Exotic_Armor_Hunter_","label":"Assassin's Cowl","type":"name"},{"id":"Heal_Clip_Weapon_Trait_Solar","label":"Heal Clip","type":"name"},{"id":"Barrier_Other_","label":"Barrier","type":"name"},{"id":"Scorch_Debuff_Hunter_Warlock_Titan_Solar_","label":"Scorch","type":"name"},{"id":"Unstoppable_Other_","label":"Unstoppable","type":"name"}]

const debug = false;

type Props = {
  selectedAutocompleteOptions: Array<AutosuggestOption>,
  setSelectedAutocompleteOptions: (arg: Array<AutosuggestOption>) => void,
  graphData: Graph | null
}
const LoadoutChecker = ({ selectedAutocompleteOptions, setSelectedAutocompleteOptions, graphData }: Props) => {
  if (!graphData) return null;

  const limits: Record<string, number> = {
    supers: 1,
    aspects: 2,
    fragments: 0,
    grenades: 1,
    melees: 1,
    classAbility: 1,
    exoticWeapons: 1,
    exoticArmors: 1,
  };

  const options = selectedAutocompleteOptions
    .filter(a => a.id !== null)
    .map(a => graphData.getNodeAttributes(a.id));

  for (const option of options) {
    if (option.nodeType === 'Aspect') {
      limits.fragments += (option.fragmentslots || 2);
    }
  }

  // @ts-ignore
  const remaining = {...limits} as Record<string, number>;

  for (const option of options) {
    switch(option.nodeType) {
      case 'Super':
        remaining.supers -= 1;
        break;
      case 'Aspect':
        remaining.aspects -= 1;
        break;
      case 'Fragment':
        remaining.fragments -= 1;
        break;
      case 'Grenade':
        remaining.grenades -= 1;
        break;
      case 'Melee':
        remaining.melees -= 1;
        break;
      case 'Class Ability':
        remaining.classAbility -= 1;
        break;
      case 'Exotic Weapon':
        // remaining.weapons -= 1;
        remaining.exoticWeapons -= 1;
        break;
      case 'Exotic Armor':
        remaining.exoticArmors -= 1;
        break;
      default: break;
    }
  }
  if (debug) {
    console.log('limit', remaining);
    console.log('remaining', remaining);
    console.log('options', options);
    console.log('selectedAutocompleteOptions', JSON.stringify(selectedAutocompleteOptions));
  }

  const text: Array<any> = [];
  for (const key of Object.keys(remaining)) {
     const used = limits[key] - remaining[key];
     let color = ''
     if (remaining[key] > 0 || (remaining[key] === 0 && used === 0)) {
       color = "default";
     } else if (remaining[key] === 0) {
       color = 'success';
     } else {
       color = 'error'
     }

     let name = '';
     switch (key) {
      case 'supers':
        name ='Super';
        break;
      case 'aspects':
        name = 'Aspects';
        break;
      case 'fragments':
        name = 'Fragments'
        break;
      case 'exoticWeapons':
        name = "Exotic Weapon"
        break;
      case 'exoticArmors':
        name= "Exotic Armor"
        break;
      case 'melees':
        name ="Melee"
        break;
      case 'grenades':
        name="Grenade"
        break;
      case 'classAbility':
        name="Class Ability"
        break;
      default: break;
     }

     text.push({ key, name, used, limit:limits[key], fraction: `${used}/${limits[key]}`, color });
  }

  return (
    <Paper
      elevation={3}
      sx={{
        zIndex: 0,
        position: "absolute",
        top: "110px",
        right: "10px",
        padding: "10px",
        textAlign: 'right',
      }}>
      {text.map(t => (
        <Chip
          variant={"filled"}
          size={'small'}
          avatar={
            <span
              style={{
                padding: '5px',
                paddingTop: '10px',
                fontSize: '1em',
                color: t.color === 'default' ? 'black' : 'white',
              }}>
            {t.fraction}
          </span>}
          label={`${t.name}`}
          key={t.key}
          sx={{margin: '5px'}}
          color={t.color} />
      ))}
    </Paper>
  );
}

export default LoadoutChecker;
