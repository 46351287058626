import {useState} from 'react';
import {
  Paper,
  FormControlLabel,
  TextField,
  Checkbox,
  Chip,
  Divider,
} from "@mui/material";
import { useSigma } from "@react-sigma/core";

export default function Edit({ data: prop }: any) {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const [data, setData] = useState(prop);

  if (data === null) return <div />;

  return (
    <Paper
      elevation={5}
      sx={{
        top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)',
        padding: '25px', width: '60%', height: '70%', overflow: 'scroll'
      }}>

      <h2>Editing {data.label}
        <Chip sx={{margin:'10px'}} variant='filled' label={data.attributes.nodeType}/>
        {data.attributes.subclass && <Chip sx={{margin:'10px'}} variant='filled' label={data.attributes.subclass}/>}
        {data.attributes?.elements?.map((value:string) => <Chip sx={{margin:'10px'}} variant='filled' label={value} />)}
        {data.attributes?.weaponslot?.map((value:string) => <Chip sx={{margin:'10px'}} variant='filled' label={value} />)}
      </h2>

      <Divider />

      <h4> Relationships </h4>

      <ul>
        {(data.relations ?? []).map((a: string) => {
          const datum = {
            edgeKey: a,
            label: graph.getEdgeAttribute(a, 'label'),
            generated: graph.getEdgeAttribute(a, 'generated'),
            sourceNodeKey: data.key,
            targetNodeKey: graph.extremities(a).find(a => a !== data.key),
          };

          return (
            <li style={{ margin: '10px' }}>
              {datum.label + ' -> ' + graph.getNodeAttribute(datum.targetNodeKey, 'label') + ' (' + datum.targetNodeKey + ')'}
            </li>
          );
        })}
      </ul>

      <Divider />

      <h4> Metadata </h4>

      <FormControlLabel
        label="hasAbilityToSwapToSuperElement"
        control={ <Checkbox checked={data.attributes.hasAbilityToSwapToSuperElement} /> } />
      <FormControlLabel
        label="hasSplashDamage"
        control={ <Checkbox checked={data.attributes.hasSplashDamage} /> } />
      <FormControlLabel
        label="hasCrowdControlBenefits"
        control={ <Checkbox checked={data.attributes.hasCrowdControlBenefits} /> } />
      <FormControlLabel
        label="hasHealingBenefits"
        control={ <Checkbox checked={data.attributes.hasHealingBenefits} /> } />
      <FormControlLabel
        label="hasIncomingDamageReduction"
        control={ <Checkbox checked={data.attributes.hasIncomingDamageReduction} /> } />
      <FormControlLabel
        label="hasEnemyOutputDamageReduction"
        control={ <Checkbox checked={data.attributes.hasEnemyOutputDamageReduction} /> } />
      <FormControlLabel
        label="hasMagazineLongevityBenefits"
        control={ <Checkbox checked={data.attributes.hasMagazineLongevityBenefits} /> } />
      <FormControlLabel
        label="hasMobilityBenefits"
        control={ <Checkbox checked={data.attributes.hasMobilityBenefits} /> } />
      <FormControlLabel
        label="hasClassCooldownReduction"
        control={ <Checkbox checked={data.attributes.hasClassCooldownReduction} /> } />
      <FormControlLabel
        label="hasGrenadeCooldownReduction"
        control={ <Checkbox checked={data.attributes.hasGrenadeCooldownReduction} /> } />
      <FormControlLabel
        label="hasMeleeCooldownReduction"
        control={ <Checkbox checked={data.attributes.hasMeleeCooldownReduction} /> } />
      <FormControlLabel
        label="hasAbilityDamageIncrease"
        control={ <Checkbox checked={data.attributes.hasAbilityDamageIncrease} /> } />
      <FormControlLabel
        label="hasMeleeDamageIncrease"
        control={ <Checkbox checked={data.attributes.hasMeleeDamageIncrease} /> } />
      <FormControlLabel
        label="hasWeaponDamageIncrease"
        control={ <Checkbox checked={data.attributes.hasWeaponDamageIncrease} /> } />
    </Paper>
  );
}
