import {
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useSigma } from "@react-sigma/core";

import Edit from './Edit';

const columns: any = [
  {
    id: 'label',
    label: 'Label',
    sx: {},
  },
  {
    id: 'relations',
    label: 'Relations',
    sx: {},
    format: (value: any) => JSON.stringify(value, null, 2),
  },
  {
    id: 'attributes',
    label: 'Attributes',
    sx: {},
    format: (value: any) => JSON.stringify(value, null, 2),
  },
];

export default function Admin() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [editNode, setEditNode] = useState<any>(null);
  const sigma = useSigma();
  const graph = sigma.getGraph();

  const data = graph.nodes()
    .map((k) => {
      const attributes = graph.getNodeAttributes(k);
      const row = {
        label: attributes.label,
        attributes,
        // grab only relations that are default
        relations: graph.outboundEdges(k).filter(a => a.includes('-REFERENCES->')),
        key: k,
      };
      return row;
    })
    .filter(a => a.relations.length > 0)
    .sort((a, b) => {
      if (a.label === b.label) return 0;
      else if (a.label > b.label) return 1;
      else return -1;
    })
  const rows = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <IconButton onClick={() => setOpen(!open)}>
        <AdminPanelSettingsIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Paper
          elevation={5}
          sx={{
            top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)',
            padding: '15px', width: '90%', height: '90%', overflow: 'scroll'
          }}>

          <Modal
            open={editNode !== null}
            onClose={() => setEditNode(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Edit data={editNode} />
          </Modal>

          <TableContainer sx={{ maxHeight: '90%' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column: any) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={column.sx || {}}
                      size={'small'}
                      variant={'head'}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.key}>
                      <TableCell key={row.key + 'edit'}>
                        <IconButton onClick={() => setEditNode(row)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      {columns.map((column: any) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align} sx={column.sx || {}} variant={'body'}>
                            {column.format
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Modal>
    </div>
  );
}
