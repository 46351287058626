import { Button, IconButton, Modal, Paper, Typography } from "@mui/material";
import { memo, useState } from "react";
import ReportIcon from '@mui/icons-material/Report';
import Markdown from 'react-markdown'

export const changelog = [
  {
    name: 'Pre-alpha 0.1',
    items: [
      'Search items by name, type, element, and various other attributes.',
      'Hover on a node to see its details and highlight related nodes',
      'Making a selection will generate and display a new subgraph which includes only relevant nodes and highlights those selected',
      'Double click on a node to select it, or unselect if it is already selected',
      'Drag and drop allows repositioning nodes',
      'Layout and zoom can be recalculated by clicking "reformat"',
      'Loadout pane will indicate missing or excessive number of build components'
    ]
  },
  {
    name: 'Pre-alpha 0.2',
    items: ['Filters which select multiple items no longer result in a crash.']
  },
  {
    name: 'Pre-alpha 0.3',
    items: [
      "Implement `super`, `aspect`, `fragment`, `grenade`, `melee`, `classAbility`, `exoticWeapon`, and `exoticArmor` search prefixes.",
      "Display node type in search selection tags."
    ]
  },
  {
    name: 'Pre-alpha 0.4',
    items: [
      "Drag-n-drop now also repositions the nodes neighbors, if they have less connections than the dragged node",
      "The graph will now attempt to maintain (approximate) node positions when adding or removing filters"
    ]
  },
  {
    name: 'Pre-alpha 0.5',
    items: [
      "Relevant Neighbor Determination: Get rid of node-type logic and use edge directionality instead. This highlights some underlying data issues, but is ultimately a more accurate display algorithm.",
      "Indicate edge directionality with arrows on the lines",
      "Make lines wider when adjacent to a hovered node"
    ]
  },
  {
    name: 'Pre-alpha 0.6',
    items: [
      "Drag and drop no longer moves all neighbors by default.",
      "Holding SHIFT when using drag and drop will now also drag neighbors.",
    ]
  },
];


const ReleaseNotes = () => {
  const latestViewedChangelogVersion = window.localStorage.getItem('version');
  const idx = changelog.findIndex(a => a.name === latestViewedChangelogVersion);
  const [open, setOpen] = useState((idx + 1) < changelog.length);

  const relevantEntries = changelog
    .slice(idx + 1)
    .map(a =>  `#### ${a.name}\n${a.items.map(i => "- " + i).join('\n')}`)

  relevantEntries.reverse();

  const markdown = relevantEntries.join('\n\n');

  const closeModal = () => {
    setOpen(false);
    window.localStorage.setItem('version', changelog[changelog.length - 1].name)
  }

  return (
    <div>
      <IconButton onClick={() => setOpen(!open)}>
        <ReportIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={5}
          sx={{
            top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)',
            padding: '15px', maxHeight: '80%', overflow: 'scroll'
          }}>
          <Typography variant="h6" component="h3">
            Release Notes
          </Typography>
          <Typography>
            <Markdown>{markdown}</Markdown>
          </Typography>
          <Button onClick={closeModal} sx={{ float: 'right'}}>Got it</Button>
        </Paper>
      </Modal>
    </div>
  );
}

export default memo(ReleaseNotes, () => true);
