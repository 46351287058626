import Graph from "graphology";
import { Attributes } from "graphology-types";
import {AutosuggestOption} from './util';

const caseInsensitiveIncludes = (a: string, b: string) => {
  return (a ?? '').toLowerCase().includes((b ?? '').toLowerCase());
}

const typeOptions = ['Exotic Class Item Perk','Class Ability', 'Grenade', 'Super', 'Melee', 'Aspect', 'Fragment', 'Debuff', 'Buff', 'Elemental Pickup', 'Exotic Weapon', 'Exotic Armor', 'Activity Armor Mod', 'Other', "Weapon Trait Enhanced", "Weapon Trait", "Weapon Perk", "Weapon Mod", "Weapon Trait Origin", "Armor Mod General", "Artifact Perk"];
const classOptions = ['Hunter', 'Warlock', 'Titan'];
const weaponSlotOptions = ['Kinetic', 'Energy', 'Heavy'];
const subclassOptions = ['Solar', 'Void', 'Arc', 'Strand', 'Stasis', 'Prismatic'];
const elementOptions = ['Solar', 'Void', 'Arc', 'Strand', 'Stasis', 'Prismatic', 'Kinetic'];
const booleanOptions = ['true', 'false'];

const prefixTypes = [
  'type',
  'name',
  'class',
  'elements',
  'subclass',
  'weaponslot',
  'hasAbilityToSwapToSuperElement',
  'hasSplashDamage',
  'hasCrowdControlBenefits',
  'hasHealingBenefits',
  'hasIncomingDamageReduction',
  'hasEnemyOutputDamageReduction',
  'hasMagazineLongevityBenefits',
  'hasMobilityBenefits',
  'hasClassCooldownReduction',
  'hasGrenadeCooldownReduction',
  'hasMeleeCooldownReduction',
  'hasAbilityDamageIncrease',
  'hasMeleeDamageIncrease',
  'hasWeaponDamageIncrease',

  // derived
  'super',
  'aspect',
  'fragment',
  'grenade',
  'melee',
  'classAbility',
  'exoticWeapon',
  'exoticArmor'
];

const normalizeCategoryOptions = (type: string) => (label: string): AutosuggestOption => {
  return { id: null, label, type };
}

// Does what it says on the tin
export const getAutocompleteOptions = (graph: Graph, query: string): Array<AutosuggestOption> => {
  if (!query) return [];
  const indexOfColon = query.indexOf(':');

  // Return prefix/label options
  if (!query || indexOfColon === -1) {
    return prefixTypes.map(a => normalizeCategoryOptions('meta')(a + ':'));
  }

  //
  // Sanitize and calculate type + value
  //
  let value = indexOfColon !== -1
    ? query.slice(indexOfColon + 1)
    : query
  value = value.trim();

  let type = 'name'
  if (query.includes(':')) {
    type = query.split(':')[0]
  }
  // TODO: allow implied "name" without typing it
  if (!prefixTypes.includes(type) && indexOfColon > -1) {
    // alert('invalid prefix, using name instead');
    type = 'name';
  }

  //
  // Get Options for type
  //
  if (type === 'name') {
    if (value.length > 1) {
      const newValues: Array<AutosuggestOption> = [];
      graph.forEachNode((key: string, attributes: Attributes): void => {
        if (caseInsensitiveIncludes(attributes.label, value)) {
          newValues.push({ id: key, label: attributes.label, type, subType: attributes.nodeType });
        }
      });
      return newValues;
    }
  } else if (type === 'super'){
    if (value.length > 1) {
      const newValues: Array<AutosuggestOption> = [];
      graph.forEachNode((key: string, attributes: Attributes): void => {
        if (caseInsensitiveIncludes(attributes.label, value) && attributes.nodeType === 'Super') {
          newValues.push({ id: key, label: attributes.label, type: 'name', subType: type });
        }
      });
      return newValues;
    }
  } else if (type ==='aspect'){
    if (value.length > 1) {
      const newValues: Array<AutosuggestOption> = [];
      graph.forEachNode((key: string, attributes: Attributes): void => {
        if (caseInsensitiveIncludes(attributes.label, value) && attributes.nodeType === 'Aspect') {
          newValues.push({ id: key, label: attributes.label, type: 'name', subType: type });
        }
      });
      return newValues;
    }
  } else if (type ==='fragment'){
    if (value.length > 1) {
      const newValues: Array<AutosuggestOption> = [];
      graph.forEachNode((key: string, attributes: Attributes): void => {
        if (caseInsensitiveIncludes(attributes.label, value) && attributes.nodeType === 'Fragment') {
          newValues.push({ id: key, label: attributes.label, type: 'name', subType: type });
        }
      });
      return newValues;
    }
  } else if (type ==='grenade'){
    if (value.length > 1) {
      const newValues: Array<AutosuggestOption> = [];
      graph.forEachNode((key: string, attributes: Attributes): void => {
        if (caseInsensitiveIncludes(attributes.label, value) && attributes.nodeType === 'Grenade') {
          newValues.push({ id: key, label: attributes.label, type: 'name', subType: type });
        }
      });
      return newValues;
    }
  } else if (type ==='melee'){
    if (value.length > 1) {
      const newValues: Array<AutosuggestOption> = [];
      graph.forEachNode((key: string, attributes: Attributes): void => {
        if (caseInsensitiveIncludes(attributes.label, value) && attributes.nodeType === 'Melee') {
          newValues.push({ id: key, label: attributes.label, type: 'name', subType: type });
        }
      });
      return newValues;
    }
  } else if (type ==='classAbility'){
    if (value.length > 1) {
      const newValues: Array<AutosuggestOption> = [];
      graph.forEachNode((key: string, attributes: Attributes): void => {
        if (caseInsensitiveIncludes(attributes.label, value) && attributes.nodeType === 'Class Ability') {
          newValues.push({ id: key, label: attributes.label, type: 'name', subType: type });
        }
      });
      return newValues;
    }
  } else if (type ==='exoticWeapon'){
    if (value.length > 1) {
      const newValues: Array<AutosuggestOption> = [];
      graph.forEachNode((key: string, attributes: Attributes): void => {
        if (caseInsensitiveIncludes(attributes.label, value) && attributes.nodeType === 'Exotic Weapon') {
          newValues.push({ id: key, label: attributes.label, type: 'name', subType: type });
        }
      });
      return newValues;
    }
  } else if (type ==='exoticArmor'){
    if (value.length > 1) {
      const newValues: Array<AutosuggestOption> = [];
      graph.forEachNode((key: string, attributes: Attributes): void => {
        if (caseInsensitiveIncludes(attributes.label, value) && attributes.nodeType === 'Exotic Armor') {
          newValues.push({ id: key, label: attributes.label, type: 'name', subType: type });
        }
      });
      return newValues;
    }
  } else if (type === 'type') {
    return typeOptions
      .filter((a: string) => caseInsensitiveIncludes(a, value))
      .map(normalizeCategoryOptions(type));
  } else if (type === 'class') {
    return classOptions
      .filter((a: string) => caseInsensitiveIncludes(a, value))
      .map(normalizeCategoryOptions(type));
  } else if (type === 'elements') {
    return elementOptions
      .filter((a: string) => caseInsensitiveIncludes(a, value))
      .map(normalizeCategoryOptions(type));
  } else if (type === 'subclass') {
    return subclassOptions
      .filter((a: string) => caseInsensitiveIncludes(a, value))
      .map(normalizeCategoryOptions(type));
  } else if (type === 'weaponslot') {
    return weaponSlotOptions
      .filter((a: string) => caseInsensitiveIncludes(a, value))
      .map(normalizeCategoryOptions(type));
  } else if ([
    'hasAbilityToSwapToSuperElement',
    'hasSplashDamage',
    'hasCrowdControlBenefits',
    'hasHealingBenefits',
    'hasIncomingDamageReduction',
    'hasEnemyOutputDamageReduction',
    'hasMagazineLongevityBenefits',
    'hasMobilityBenefits',
    'hasClassCooldownReduction',
    'hasGrenadeCooldownReduction',
    'hasMeleeCooldownReduction',
    'hasAbilityDamageIncrease',
    'hasMeleeDamageIncrease',
    'hasWeaponDamageIncrease'
    ].includes(type)) {
      return booleanOptions
        .filter((a: string) => caseInsensitiveIncludes(a, value))
        .map(normalizeCategoryOptions(type));
  }

  return [] as Array<AutosuggestOption>;
};


export const getResultsForAutocompleteOption = (graph: Graph, value: AutosuggestOption): Array<string> => {
  const newValues: Array<string> = [];

  if (value.type === 'name') {
    // It's already guaranteed to be a specific item, so no searching necessary
    if (!value.id) return [];
    return [value.id];
  } else {
    // Evaluate nodes according to the specified query
    graph.forEachNode((key: string, attributes: Attributes): void => {
      switch (value.type) {
        case "type": {
          if (value.label === attributes.nodeType) {
            newValues.push(key);
          }
          break;
        }
        case 'class': {
          if ((attributes.class || []).includes(value.label)) {
            newValues.push(key);
          }
          break;
        }
        case 'elements': {
          if ((attributes.elements || []).includes(value.label)) {
            newValues.push(key);
          }
          break;
        }
        case 'subclass': {
          if ((attributes.subclass || []).includes(value.label)) {
            newValues.push(key);
          }
          break;
        }
        case 'weaponslot': {
          if ((attributes.weaponslot || []).includes(value.label)) {
            newValues.push(key);
          }
          break;
        }
        case 'hasAbilityToSwapToSuperElement':
        case 'hasSplashDamage':
        case 'hasCrowdControlBenefits':
        case 'hasHealingBenefits':
        case 'hasIncomingDamageReduction':
        case 'hasEnemyOutputDamageReduction':
        case 'hasMagazineLongevityBenefits':
        case 'hasMobilityBenefits':
        case 'hasClassCooldownReduction':
        case 'hasGrenadeCooldownReduction':
        case 'hasMeleeCooldownReduction':
        case 'hasAbilityDamageIncrease':
        case 'hasMeleeDamageIncrease':
        case 'hasWeaponDamageIncrease': {
          // boolean is in the label field and this is how we'll parse it
          const bool = value.label === "true"
          if (attributes[value.type] === bool) {
            newValues.push(key);
          }
          break;
        }
        default: {
          alert(`switch case failed: ${JSON.stringify(value, null, 2)}`)
        }
      }
    });
  }
  return newValues;
};

