const publicUrl = '/graph.json';
const apiUrl = 'http://localhost:3000/graph.json';

function fetchGraph() {
  console.log('Fetching graph');
  return fetch(publicUrl)
    .then(res => {
      if (res.status > 200) throw new Error(`Request failed with status code ${res.status}`);
      return res.json();
    })
}

function formatData(data: any){
  console.log('Formatting graph');

  for (const node of data.nodes) {
    node.attributes.x = Math.random();
    node.attributes.y = Math.random();
    node.attributes.label = node.attributes.name.slice();
    node.attributes.nodeType = node.attributes.type.slice();
    node.attributes.type = 'circle';
  }

  for (const edge of data.edges) {
    if (edge.attributes.type) {
      edge.attributes.label = edge.attributes.type.slice();
      edge.attributes.type = 'arrow';
      edge.attributes.size = 1;
    }
  }

  return data;
}

export default async function getGraph() {
  const data = await fetchGraph();
  return formatData(data);
}
