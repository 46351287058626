import React from "react";
import { useSigma } from "@react-sigma/core";
import { Chip, Divider, Paper } from "@mui/material";

const debug = false;

const DetailPane = ({ hoveredNode }: {hoveredNode: string|null}) => {
  const sigma = useSigma();
  if (!hoveredNode) return null;
  const attributes = sigma.getGraph().getNodeAttributes(hoveredNode);
  if (debug) {
    console.log('hovered: '+hoveredNode, attributes);
  }

  return (
    <Paper
      elevation={3}
      sx={{
        zIndex: 10000,
        position: "absolute",
        top: "115px",
        width: "200px",
        left: "15px",
        padding: "15px",
      }}
    >
      {attributes.label}
      <br/>
      <Chip variant='filled' label={attributes.nodeType}/>
      {attributes.subclass && <Chip variant='filled' label={attributes.subclass}/>}
      {attributes?.elements?.map((value:string) => <Chip variant='filled' label={value} />)}
      {attributes?.weaponslot?.map((value:string) => <Chip variant='filled' label={value} />)}
      <br/>
      <Divider />
      {attributes.description}
    </Paper>
  );
};

export default DetailPane;
