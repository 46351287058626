import React, { useState } from "react";

import { SigmaContainer, ControlsContainer, ZoomControl, FullScreenControl } from "@react-sigma/core";
import forceAtlas2 from 'graphology-layout-forceatlas2';

import { GraphLoader } from "./GraphLoader";
import AppBar from "./AppBar";
import LoadoutChecker from "./LoadoutChecker";
import ReleaseNotes, {changelog} from "./ReleaseNotes";
import DetailPane from "./DetailPane";
import {AutosuggestOption} from "../lib/util";
import Graph from "graphology";



// Settings generated using: forceAtlas2.inferSettings(graph);
const settings = {
  barnesHutOptimize: false,
  strongGravityMode: true,
  gravity: 0.2,
  scalingRatio: 10,
  slowDown: 8.126,
};

function App() {
  const [graphData, setGraphData] = useState<Graph | null>(null);
  const [search, setSearch] = useState<string>("");
  const [selections, setSelections] = useState<Array<string>>([]);
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);
  const [draggedNode, setDraggedNode] = useState<string | null>(null);
  const [autocompleteOptions, setAutocompleteOptions] = useState<Array<AutosuggestOption>>([]);
  const [selectedAutocompleteOptions, setSelectedAutocompleteOptions] = useState<Array<AutosuggestOption>>([]);

  // @ts-ignore
  window.graphData = graphData;
  //@ts-ignore
  window.setSelectedAutocompleteOptions = setSelectedAutocompleteOptions;

  const runLayout = (graph: Graph|null = graphData, iterations = 500) => {
    if (!graph) return;

    const positions = forceAtlas2(graph, {iterations, settings });
    Object.keys(positions).forEach((key) => {
      const {x,y} = positions[key];
      graph.setNodeAttribute(key, 'x', x);
      graph.setNodeAttribute(key, 'y', y);
    })
  }
  //@ts-ignore
  window.runLayout = (timer?:number) => runLayout(sigma.getGraph(), timer);

  const props = {
    runLayout, layoutSettings: settings,

    selections, setSelections,
    search, setSearch,
    selectedAutocompleteOptions, setSelectedAutocompleteOptions,
    hoveredNode, setHoveredNode,
    draggedNode, setDraggedNode,
    graphData, setGraphData,
    autocompleteOptions, setAutocompleteOptions
  };

  return (
    <div className="App">
      <SigmaContainer
        style={{ position: 'absolute', top: 0, left: 0, width: "100vw" }}
        settings={{ allowInvalidContainer: true }}>
        <AppBar {...props} />
        <GraphLoader {...props} />
        <DetailPane hoveredNode={hoveredNode} />
        <ReleaseNotes />
        <span style={{ color: 'rgba(0,0,0,.5)', position: 'absolute', bottom:'5px', left: '5px'}}>{changelog[changelog.length - 1].name}</span>
        <ControlsContainer position={"top-right"}>
          <LoadoutChecker  {...{selectedAutocompleteOptions, setSelectedAutocompleteOptions, graphData}} />
        </ControlsContainer>
        <ControlsContainer position={"bottom-right"}>
          <ZoomControl />
          <FullScreenControl />
        </ControlsContainer>
      </SigmaContainer>
    </div>
  );
}

export default App;
