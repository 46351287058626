import { IconButton, Modal, Paper, Typography } from "@mui/material";
import { memo, useState } from "react";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Markdown from 'react-markdown'

// TODO: Options
// - drag and drop pulls neighbors if they are less connected than the clicked node
// - which neighbors are shown

const TODOs = `
TODO(next):
- [panda] Flesh out the detail hover pane
- [grey] Create node/edge editor
- [grey] Improve performance of layout? its taking a full second sometimes

TODO(config)
- Make layout algorithm configurable
- Make drag and drop groups configurable (shift click drag?)

Feedback:
- Create a new user input for class and subclass to limit users to valid combinations
- Visual differentiation between selected nodes and on-hover
- Node label functionality -- can you add clickable icons or something?
- color code search selection chips
- Hovering on the loadout tracker chips should highlight relevant nodes
- Side panel functionality, but no solution yet -- needs to be static in some way so it doesn't go away on hover b/c sometimes the information is too long, and I can't scroll
- Modes / options for showing the associated nodes when selecting a node (maybe I don't actually want to see all the other related nodes when I select one)
- Ability to hide nodes?

TODO(chores):
- serve metadata from public folder or use constants
- optimize to remove reundant rerenders

TODO(data):
- How to incorporate grapple melee?
- stasis overshield is linked to restoration
- marksman dodge doesn't connect to healclip
- osmosis and osmosis enhanced are not linked
- Diamond lance classification? construct vs elemental pickup

TODO(longterm):
- read in user's vaults and pattern progress to support weapon rolls
- images for each node
- improve data now that ui has reached MVP
- Loadout Tracker
  - armor mods?
  - blink?
  - activity modifiers?
`

const Todo = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <IconButton onClick={() => setOpen(!open)}>
        <NoteAltIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper
          elevation={5}
          sx={{
            top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)',
            padding: '15px', height: '80%', overflow: 'scroll'
          }}>
          <Typography variant="h5" component="h2">
            Issue/Enhancement Tracking
          </Typography>
          <Typography>
            <Markdown>{TODOs}</Markdown>
          </Typography>
        </Paper>
      </Modal>
    </div>
  );
}

export default memo(Todo, () => true);
